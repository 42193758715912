const ThirdIcon = () => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Flat">
        <g id="Color">
          <polygon
            fill="#212529"
            points="8.26 3 25.94 33.62 38.06 26.62 24.42 3 8.26 3"
          />

          <path
            d="M38.06,26.62l-7.21-12.5-3.72,6.44a21.53,21.53,0,0,0-7,3l5.8,10Z"
            fill="#111315"
          />

          <polygon
            fill="#dd051d"
            points="34.6 28.62 29.4 31.62 12.87 3 19.8 3 34.6 28.62"
          />

          <polygon
            fill="#212529"
            points="39.58 3 25.94 26.62 38.06 33.62 55.74 3 39.58 3"
          />

          <path
            d="M34.6,28.62l-6.06-10.5-1.42,2.46a21.44,21.44,0,0,0-3.46,1.1l5.74,9.94Z"
            fill="#a60416"
          />

          <path
            d="M43.86,23.58a21.46,21.46,0,0,0-14.17-3.45l-3.75,6.49,12.12,7Z"
            fill="#111315"
          />

          <polygon
            fill="#dd051d"
            points="51.13 3 34.6 31.62 29.4 28.62 44.2 3 51.13 3"
          />

          <path
            d="M34.6,31.62l5.74-9.94a21.41,21.41,0,0,0-6-1.55L29.4,28.62Z"
            fill="#a60416"
          />

          <circle cx="32" cy="41.5" fill="#cf8255" r="19.5" />

          <circle cx="32" cy="41.5" fill="#804b2b" r="14.5" />

          <path
            d="M36.54,41.5A4.52,4.52,0,0,0,38.38,38c0-2.76-2.86-5-6.38-5s-6.37,2.24-6.37,5h3.92a2,2,0,0,1,3.9-.29c.17,1.23-.77,2.73-2,2.73v2.12c2.22,0,2.84,3.5.72,4.32A2,2,0,0,1,29.55,45H25.63c0,2.76,2.85,5,6.37,5s6.38-2.24,6.38-5A4.52,4.52,0,0,0,36.54,41.5Z"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
};

export default ThirdIcon;
